import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Row, Col, Alert, Container } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { loginCompanyAdmin, ADMIN_LOGIN, cancelMFA } from "../../../store/actions";
import CarouselPage from "../CarouselPage";
import { SpinnerCircular } from "spinners-react";
import { withTranslation } from "react-i18next";
import loginlogo from "../../../assets/images/loginlogo.png";
import styled from "styled-components";
import MfaCode from "../MfaCode";
import { useV2, getV2LoginUrl } from "src/helpers/v2Helper";

const Login = (props: any) => {
  const defaultCompanyCode: string = "77777";
  const companyName: string = "Hendy";

  const dispatch = useDispatch();

  const [COMPANY_LOGIN, setCOMPANY_LOGIN] = useState<any>(false);
  const [stuffLogin, setStuffLogin] = useState<any>(true);

  const [companyCode, setCompanyCode] = useState("");
  const [password, setPassword] = useState("");
  const [ischecked, setIschecked] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const { errorMsg, loading, mfaRequired } = useSelector((state: any) => state.login);

  sessionStorage.companyCode = defaultCompanyCode;

  if (useV2()) {
    //redirect to dashboard to trigger SSO login
    window.location.href = "/dashboard";
  }


  useEffect(() => {
    setErrMsg(errorMsg);
  }, [errorMsg]);

  const handleCOMPANY_LOGIN = (event: any, values: any) => {
    setErrMsg("");
    if (companyCode != defaultCompanyCode) {
      setErrMsg("You are not the right user");
      return;
    }
    dispatch(loginCompanyAdmin(values, props.history));

    if (ischecked && companyCode !== "") {
      sessionStorage.companyCode = companyCode;
      //sessionStorage.password = password;
      sessionStorage.checkbox = ischecked;
    }
  };

  const handleStuffLogin = (event: any, values: any) => {
    dispatch(ADMIN_LOGIN(values, props.history));
  };

  const handleClickCompany = () => {
    setCOMPANY_LOGIN(true);
    setStuffLogin(false);
  };

  const handleClickStaff = () => {
    setCOMPANY_LOGIN(false);
    setStuffLogin(true);
  };

  const cancelMFALogin = () => {
    dispatch(cancelMFA())
  }

  const onChange = (e: any) => {
    if (e.target.name === "companyCode") {
      setCompanyCode(e.target.value);
    }
    if (e.target.name === "password") {
      setPassword(e.target.value);
    }
  };

  const onChangeCheckbox = (e: any) => {
    setIschecked(e.target.checked);
  };

  useEffect(() => {
    if (sessionStorage.checkbox && sessionStorage.companyCode !== "") {
      setIschecked(true);
      setCompanyCode(sessionStorage.companyCode);
      setPassword(sessionStorage.password);
    }
  }, []);

  return (
    <React.Fragment>
      <MetaTags>
        <title>{props.t("LoginPageSEO")}</title>
      </MetaTags>
      <div className="auth-page">
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col lg={4} md={5} className="col-xxl-3">
              <div className="auth-full-page-content d-flex percentPadding-10">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="auth-content my-auto">


                      {mfaRequired && (
                        <MfaCode
                          onCancel={() => cancelMFALogin()} history={props.history}></MfaCode>
                      )}

                      {!mfaRequired && (
                        <div>
                          <div className="text-center">
                            <LogoImg className="avatar-edit" src={loginlogo} />
                            <h5 className="mb-0"> {props.t("LoginWelcome")}</h5>
                            <p className="text-muted mt-2">
                              {props.t("LoginTitle") + companyName + " Admin."}
                            </p>
                          </div>
                          <div className="custom-login-switch d-flex">
                            <div
                              className="login-staff d-flex justify-content-center align-items-center"
                              onClick={handleClickStaff}
                            >
                              {stuffLogin && (
                                <div className="switch-slect-staff"></div>
                              )}
                              <span
                                style={{ color: stuffLogin ? "white" : "#000" }}
                              >
                                Colleague
                              </span>
                            </div>
                            <div
                              className="login-company d-flex justify-content-center align-items-center relative"
                              onClick={handleClickCompany}
                            >
                              {COMPANY_LOGIN && (
                                <div className="switch-slect-company"></div>
                              )}
                              <span
                                style={{ color: COMPANY_LOGIN ? "white" : "#000" }}
                              >
                                Head Office
                              </span>
                            </div>
                          </div>

                          {stuffLogin && (
                            <AvForm
                              className="custom-form mt-4 pt-2"
                              onValidSubmit={(e: any, v: any) => {
                                handleStuffLogin(e, v);
                              }}
                            >
                              {errMsg ? (
                                <Alert color="danger"> {errMsg} </Alert>
                              ) : null}
                              <div className="mb-3">
                                <AvField
                                  name="email"
                                  label="E-Mail"
                                  placeholder="Enter Valid Email"
                                  type="email"
                                  errorMessage="Invalid Email"
                                  validate={{
                                    required: { value: true },
                                    email: { value: true },
                                  }}
                                />
                              </div>
                              <div className="mb-3">
                                <div className="d-flex align-items-start">
                                  <div className="flex-grow-1">
                                    <label className="form-label">
                                      {props.t("Password")}
                                    </label>
                                  </div>
                                  <div className="flex-shrink-0">
                                    <div className="">
                                      <Link
                                        to="/auth-recoverpw"
                                        className="text-muted"
                                      >
                                        {props.t("ForgetPassword")}
                                      </Link>
                                    </div>
                                  </div>
                                </div>

                                <div className="mb-3">
                                  <AvField
                                    name="password"
                                    value=""
                                    type="password"
                                    className="form-control"
                                    required
                                    placeholder="Enter Password"
                                  />
                                </div>
                              </div>
                              <div className="row mb-4">
                                <div className="col">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="remember-check"
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="remember-check"
                                    >
                                      {props.t("RememberMe")}
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="mb-3">
                                <button
                                  className="btn btn-primary w-100 waves-effect waves-light"
                                  type="submit"
                                  disabled={loading}
                                  style={{ height: 50 }}
                                >
                                  {loading ? (
                                    <SpinnerCircular
                                      size={30}
                                      thickness={150}
                                      speed={150}
                                      color="#36ad47"
                                      secondaryColor="rgba(0, 0, 0, 0.44)"
                                    />
                                  ) : (
                                    <> {props.t("Login")}</>
                                  )}
                                </button>
                              </div>
                            </AvForm>
                          )}

                          {COMPANY_LOGIN && (
                            <AvForm
                              className="custom-form mt-4 pt-2"
                              onValidSubmit={(e: any, v: any) => {
                                handleCOMPANY_LOGIN(e, v);
                              }}
                            >
                              {errMsg ? (
                                <Alert color="danger"> {errMsg} </Alert>
                              ) : null}
                              <div className="mb-3">
                                <AvField
                                  name="companyCode"
                                  label={props.t("Username")}
                                  value={companyCode}
                                  onChange={onChange}
                                  className="form-control"
                                  placeholder="Enter Username"
                                  type="text"
                                  required
                                />
                              </div>
                              <div className="mb-3">
                                <div className="d-flex align-items-start">
                                  <div className="flex-grow-1">
                                    <label className="form-label">
                                      {props.t("Password")}
                                    </label>
                                  </div>
                                  <div className="flex-shrink-0">
                                    <div className="">
                                      <Link
                                        to="/auth-recoverpw"
                                        className="text-muted"
                                      >
                                        {props.t("ForgetPassword")}
                                      </Link>
                                    </div>
                                  </div>
                                </div>

                                <div className="mb-3">
                                  <AvField
                                    name="password"
                                    value={password}
                                    onChange={onChange}
                                    type="password"
                                    className="form-control"
                                    required
                                    placeholder="Enter Password"
                                  />
                                </div>
                              </div>
                              <div className="row mb-4">
                                <div className="col">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="remember-check"
                                      checked={ischecked}
                                      onChange={onChangeCheckbox}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="remember-check"
                                    >
                                      {props.t("RememberMe")}
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="mb-3">
                                <button
                                  className="btn btn-primary w-100 waves-effect waves-light"
                                  type="submit"
                                  // disabled={loading}
                                  style={{ height: 50 }}
                                >
                                  {loading ? (
                                    <SpinnerCircular
                                      size={30}
                                      thickness={150}
                                      speed={150}
                                      color="#36ad47"
                                      secondaryColor="rgba(0, 0, 0, 0.44)"
                                    />
                                  ) : (
                                    <> {props.t("Login")}</>
                                  )}
                                </button>
                              </div>
                            </AvForm>
                          )}
                        </div>
                      )}

                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <CarouselPage />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Login.propTypes = {
  history: PropTypes.object,
  t: PropTypes.any,
};

export default withTranslation()(withRouter(Login));

const LogoImg = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
`;
