import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setAuthUser } from "../../store/actions";
import { Permissions } from "src/enum/Permission";
import Unauthorized from "src/pages/Authentication/Unauthorized";
import { getV2LoginUrl, useV2 } from "src/helpers/v2Helper";

interface AuthLayoutProps {
  component: any;
  layout: any;
  isAuthProtected: any;
  useV2?: boolean; //flag to use v2 SSO login
  permission?: Permissions;
  path?: string;
  exact?: boolean;
  key?: number;
}

interface ExternalRedirectProps {
  shouldRedirect: boolean;
  url: string;
}

export const ExternalRedirect: React.FC<ExternalRedirectProps> = ({ shouldRedirect, url }) => {
  useEffect(() => {
    if (shouldRedirect) {
      // Redirect to the external URL
      window.location.href = url;
    }
  }, [shouldRedirect, url]); // Depend on the condition and URL to trigger the redirect

  return null; // Render nothing
};

const Authmiddleware = ({
  component,
  layout,
  isAuthProtected,
  permission,
  path,
  exact,
  key,
  ...rest
}: AuthLayoutProps) => {
  const dispatch = useDispatch();
  const Layout = layout;
  const Component = component;
  const use_V2 = useV2();
  const loginUrl = use_V2
    ? getV2LoginUrl()
    : '/login';

  useEffect(() => {

    console.log('useV2', use_V2);

    const accessToken: any = sessionStorage.getItem('accessToken');
    if (accessToken) {
      const strUser = sessionStorage.getItem("authUser");
      if (strUser) {
        dispatch(setAuthUser(JSON.parse(strUser)));
      }
    }
  }, [])

  const { currentUser } = useSelector((state: any) => state.login)

  const hasPermission = (): boolean => {

    if (!permission)
      return true;

    return currentUser?.permissions[permission];
  }

  return (
    <Route
      {...rest}
      render={props => {
        if (isAuthProtected && !sessionStorage.getItem("authUser")) {
          return (
            <ExternalRedirect shouldRedirect={true} url={loginUrl} />
          );
        }
        else if (!hasPermission() && props.location.pathname !== '/unauthorised') {
          return (
            <Layout>
              <Unauthorized />
            </Layout>
          );
        }

        return (
          <Layout>
            <Component {...props} />
          </Layout>
        );
      }}
    />
  );
};

export default Authmiddleware;
