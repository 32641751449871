import { IMake, IVehicle } from "src/interfaces/general";
import { post, get, put, del } from "./api_helper";
import * as url from "./url_helper";

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = sessionStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

//admin login
const companyAdminLogin = (data: any) => post(url.COMPANY_ADMIN_LOGIN, data);
const loginStaffAsync = (data: any) => post(url.STAFF_LOGIN, data);
const loginMFAAsync = (data: any) => post(url.MFA_LOGIN, data);
const resendMFAAsync = (data: any) => post(url.MFA_RESEND, data);

//companies
const fetchCompaniesAsync = () => get(url.GET_COMPANIES);
const addNewCompanyAsync = (data: any) => post(url.ADD_COMPANY, data);
const deleteCompanyAsync = (code: string) => del(`${url.DEL_COMPANY}/${code}`);
const updateCompanyAsync = (id: string, company: any) =>
  put(`${url.UPDATE_COMPANY}/${id}`, company);
const getCompanyByCodeAsync = (companyCode: string) =>
  get(`${url.GET_COMPANY_BY_ID}/${companyCode}`);

//sites
const fetchSitesByCompanyCodeAsync = (companyCode: string) =>
  get(`${url.GET_SITES_BY_COMPANYCODE}/${companyCode}`);
const addSiteAsync = (site: any) => post(url.ADD_SITE, site);
const getSiteByDocIdAsync = (docid: string) =>
  get(`${url.GET_SITE_BY_DOCID}/${docid}`);
const getSiteBySiteCodeAsync = (siteCode: string) =>
  get(`${url.GET_SITE_BY_SITECODE}/${siteCode}`);
const deleteSiteBySiteCodeAsync = (siteCode: string) =>
  del(`${url.DEL_SITE_BY_SITECODE}/${siteCode}`);
const updateSiteByDocIdAsync = (docid: string, site: any) =>
  put(`${url.UPDATE_SITE_BY_DOCID}/${docid}`, site);

//admins
const getAdminsBySiteCodeAsync = (siteCode: string) =>
  get(`${url.GET_ADMINS_BY_SITECODE}/${siteCode}`);
const getAdminsByCompanyCodeAsync = (companyCode: string, use_v2: boolean) =>
  get(`${url.GET_ADMINS_BY_COMPANYCODE}/${companyCode}?use_v2=${use_v2}`);
const addAdminAsync = (admin: any) => post(url.ADD_ADMIN, admin);
const addAdminsAsync = (admins: any) => post(url.ADD_ADMINS, admins);
const getAdminByDocIdAsync = (docid: string) =>
  get(`${url.GET_ADMIN_BY_DOCID}/${docid}`);
const updateAdminByDocIdAsync = (docid: string, admin: any) =>
  put(`${url.UPDATE_ADMIN_BY_DOCID}/${docid}`, admin);
const deleteAdminByDocIdAsyc = (docid: string) =>
  del(`${url.DEL_ADMIN_BY_DOCID}/${docid}`);

// analytics
const getFromFirebase = (from: string, to: string) =>
  get(`${url.GET_ANALYTICS_FROM_FIREBASE}?from=${from}&to=${to}`);
const getAnalyticsCustomersVehicles = (duration: string) =>
  get(`${url.GET_ANALYTICS_CUSTOMERS_VEHICLES}?duration=${duration}`);

//admin scores
const getScoresByCompanyCodeAsynce = (companyCode: string) =>
  get(`${url.GET_SCORES_BY_COMPANYCODE}/${companyCode}`);

//customers
const getCustomersBySiteCodeAsync = (siteCode: string) =>
  get(`${url.GET_CUSTOMERS_BY_SITECODE}/${siteCode}`);
const getCustomersByCompanyCodeAsync = (companyCode: string) =>
  get(`${url.GET_CUSTOMERS_BY_COMPANYCODE}/${companyCode}`);
const getCustomersAndVehiclesByCompanyCodeAsync = (companyCode: string) =>
  get(`${url.GET_CUSTOMERS_AND_VEHICLES_BY_COMPANYCODE}/${companyCode}`);
const addCustomerAsync = (customer: any) => post(url.ADD_CUSTOMER, customer);
const inviteCustomerAsync = (customer: any) =>
  post(url.INVITE_CUSTOMER, customer);
const getCustomerByDocIdAsync = (docid: string) =>
  get(`${url.GET_CUSTOMER_BY_DOCID}/${docid}`);
const updateCustomerByDocIdAsync = (docid: string, customer: any) =>
  put(`${url.UPDATE_CUSTOMER_BY_DOCID}/${docid}`, customer);
const deleteCustomerByDocIdAsync = (docid: string) =>
  del(`${url.DEL_CUSTOMER_BY_DOCID}/${docid}`);
const getGraphDataOfCustomer = (
  siteCode: string,
  start: string,
  days: number
) =>
  get(
    `${url.GET_GRAPH_DATA_OF_CUSTOMER}/${siteCode}?start=${start}&days=${days}`
  );
const getCustomersByFilter = (body: any) =>
  post(`${url.GET_CUSTOMERS_BY_FILTER}`, body);

const getCustomersByFilterCount = (body: any) =>
  post(`${url.GET_CUSTOMERS_BY_FILTER_COUNT}`, body);

//Vehicles
const getAllVehiclesAsync = () => get(`${url.GET_ALL_VEHICLES}`);
const addVehicleAsync = (vehicle: any) => post(url.ADD_VEHICLE, vehicle);
const getVehicleByDocIdAsync = (docid: string) =>
  get(`${url.GET_VEHICLE_BY_DOCID}/${docid}`);
const deleteVehicleByIdAsync = (docid: string) =>
  del(`${url.DELETE_VEHICLE_BYID}/${docid}`);
const getVehicleByRegNoAsync = (regno: string) =>
  get(`${url.GET_VEHICLE_BY_REGNO}/${regno}`);
const getVehiclesByCustomerIdAsync = (customerId: string) =>
  get(`${url.GET_VEHICLES_BY_CUSTOMERID}/${customerId}`);
const updateVehicleAsync = (vehicleId: string, vehicle: IVehicle) =>
  put(`${url.ADD_VEHICLE}/${vehicleId}`, vehicle)

//BOOKINGS
const getAllBookingsAsync = () => get(`${url.GET_ALL_BOOKINGS}`);
const getBookingsByCompanyCodeAsync = (companyCode: string) =>
  get(`${url.GET_BOOKINGS_BY_COMPANYCODE}/${companyCode}`);
const getBookingsBySiteCodeAsync = (siteCode: string) =>
  get(`${url.GET_BOOKINGS_BY_SITECODE}/${siteCode}`);
const addBookingAsync = (booking: any) => post(url.ADD_BOOKING, booking);
const getBookingByDocIdAsync = (docid: string) =>
  get(`${url.GET_BOOKING_BY_DOCID}/${docid}`);
const deleteBookingByIdAsync = (docid: string) =>
  del(`${url.DELETE_BOOKING_BYID}/${docid}`);
const getGraphDataOfBooking = (siteCode: string, start: string, days: number) =>
  get(
    `${url.GET_GRAPH_DATA_OF_BOOKING}/${siteCode}?start=${start}&days=${days}`
  );

//PRODUCTS
const getProductsBySiteCodeAsync = (siteCode: string) =>
  get(`${url.GET_PRODUCTS_BY_SITECODE}/${siteCode}`);
const getAllProductsAsync = () => get(`${url.GET_ALL_PRODUCTS}`);
const getProductByDocIdAsync = (docid: string) =>
  get(`${url.GET_PRODUCT_BYID}/${docid}`);
const addProductAsync = (product: any) => post(url.ADD_PRODUCT, product);
const deleteProductByIdAsync = (docid: string) =>
  del(`${url.DELETE_PRODUCT_BYID}/${docid}`);
const updateProductByDocIdAsync = (docid: string, product: any) =>
  put(`${url.UPDATE_PRODUCT_BY_DOCID}/${docid}`, product);
const createProductNotificationsAsync = (data: any) =>
  post(url.CREATE_PRODUCT_NOTIFICATIONS, data);
const getNotificationsByDocTypeAsync = (type: string, code: string) =>
  post(`${url.GET_NOTIFICATIONS_BY_PRODUCT}`, { type: type, code: code });
const createProductSalesAsync = (data: any) =>
  post(url.CREATE_PRODUCT_SALES, data);
const invitedCustomerProudctNotificationAsync = (data: any) =>
  post(url.INVITED_CUSTOMER_PRODUCTION_NOTIFICATION, data);
const getAllDealerProductsAsync = (data: any) =>
  post(url.GET_ALL_DEALER_PRODUCTS, data);
const createContractAsync = (data: any) => post(url.CREATE_CONTRACT_DATA, data);
const refreshWasProductsAsync = (data: any) =>
  post(url.REFRESH_WAS_PRODUCTS, data);

const setupWasDirectPayment = (contractNumber: string, data: any) =>
  post(url.SETUP_WAS_DIRECT_PAYMENT, data);
const makeContractLive = (contractNumber: string) =>
  post(url.MAKE_CONTRACT_LIVE, { contractNumber });
const getWasContractPDF = (contractNumber: string) =>
  get(url.GET_WAS_CONTRACT_PDF + contractNumber);
const updateContractNumber = (data: any) =>
  post(url.UPDATE_CONTRACT_NUMBER, data);
const receivedDocument = (data: any) =>
  post(url.RECEIVED_DOCUMENT, data);

//GeoFence
const addGeoFenceAsync = (geofence: any) => post(url.ADD_GEO_FENCE, geofence);
const getAllGeoFenceListAsync = () => get(`${url.GET_ALL_GEOFENCE_LIST}`);
const getGeoFenceByDocIdAsync = (docid: string) =>
  get(`${url.GET_GEOFENCE_BYID}/${docid}`);
const updateGeoFenceByDocIdAsync = (docid: string, geofence: any) =>
  put(`${url.UPDATE_GEOFENCE_BY_DOCID}/${docid}`, geofence);
const deleteGeoFenceByIdAsync = (docid: string) =>
  del(`${url.DELETE_GEOFENCE_BYID}/${docid}`);

//Warranty
const addWarrantyAsync = (geofence: any) => post(url.ADD_WARRANTY, geofence);
const getAllWarrantyListAsync = () => get(`${url.GET_ALL_WARRANTY_LIST}`);
const getWarrantByDocIdAsync = (docid: string) =>
  get(`${url.GET_WARRANTY_BYID}/${docid}`);
const updateWarrantyByDocIdAsync = (docid: string, geofence: any) =>
  put(`${url.UPDATE_WARRANTY_BY_DOCID}/${docid}`, geofence);
const deleteWarrantyByIdAsync = (docid: string) =>
  del(`${url.DELETE_WARRANTY_BYID}/${docid}`);

//Comms
const saveCommBySitecodeAsync = (commsData: any) =>
  post(url.SAVE_COMMS, commsData);
const getCommsBySiteCodeAsync = (siteCode: string) =>
  get(`${url.GET_COMMS_BY_SITECODE}/${siteCode}`);

//booking tool setting
const saveBookingToolBySitecodeAsync = (bookingToolData: any) =>
  post(url.SAVE_BOOKING_TOOL_SETTING, bookingToolData);
const getBookingToolDataBySiteCodeAsync = (siteCode: string) =>
  get(`${url.GET_BOOKING_TOOL_DATA_BY_SITECODE}/${siteCode}`);

//directory

const getDirectoryBySiteCodeAsync = (siteCode: string) =>
  get(`${url.GET_DIRECTORY_BY_SITECODE}/${siteCode}`);
const getSitesByServiceIdAsync = (serviceId: string) =>
  get(`${url.GET_SITES_BY_SERVICE}/${serviceId}`);
const updateDirStatusByServiceAsync = (docid: string, data: any) =>
  put(`${url.UPDATE_DIR_STATUS_BY_SERVICE}/${docid}`, data);
const getDirectoriesBySite = (site: string) =>
  get(`${url.GET_DIRECTORIES_BY_SITE}/${site}`);
const updateDirectoriesBySite = (site: string, data: any) =>
  put(`${url.UPDATE_DIRECTORIES_BY_SITE}/${site}`, data);

//Role
const getAllRolesAsync = () => get(`${url.GET_ALL_ROLES}`);
const addRoleAsync = (role: any) => post(url.ADD_ROLE, role);
const getRoleByIdAsync = (docid: string) =>
  get(`${url.GET_ROLE_BY_ID}/${docid}`);
const deleteRoleByIdAsync = (docid: string) =>
  del(`${url.DELETE_ROLE_BY_ID}/${docid}`);
const updateRoleByIdAsync = (docid: string, data: any) =>
  put(`${url.UPDATE_ROLE_BY_ID}/${docid}`, data);

//saleOfProduct
const getAllSaleOfProductsAsync = () => get(`${url.GET_ALL_SALEOFPRODUCTS}`);
const addSaleOfProductAsync = (saleOfProduct: any) =>
  post(url.ADD_SALEOFPRODUCT, saleOfProduct);
const getContractByAgreementAsync = (agreement: string) => get(`${url.GET_CONTRACT_BY_AGREEMENT}/${agreement}`);

// notification

const getNotificationsByCustomerIdAsync = (customerId: string) =>
  get(`${url.GET_NOTIFICATIONS_BY_CUSTOMERID}/${customerId}`);
const getNotificationsByCompanyCodeAsync = (companyCode: string) =>
  get(`${url.GET_NOTIFICATIONS_BY_COMPANYCODE}/${companyCode}`);
const getNotificationsBySiteCodeAsync = (siteCode: string) =>
  get(`${url.GET_NOTIFICATIONS_BY_SITECODE}/${siteCode}`);
const getNotificationsByDocIdAsync = (docid: string) =>
  get(`${url.GET_NOTIFICATIONS_BY_ID}/${docid}`);
const addNotificationAsync = (notification: any) =>
  post(url.ADD_NOTIFICATION, notification);
const deleteNotificationByIdAsync = (docid: string) =>
  del(`${url.DELETE_NOTIFICATION_BYID}/${docid}`);
const updateNotificationByIdAsync = (docid: string, data: any) =>
  put(`${url.UPDATE_NOTIFICATION_BY_DOCID}/${docid}`, data);

// notification-template

const getNotificationTemplatesByCompanyCodeAsync = (companyCode: string) =>
  get(`${url.GET_NOTIFICATION_TEMPLATES_BY_COMPANYCODE}/${companyCode}`);
const getNotificationTemplateByIdAsnc = (docid: string) =>
  get(`${url.GET_NOTIFICATION_TEMPLATE_BY_ID}/${docid}`);
const addNotificationTemplateAsync = (template: any) =>
  post(url.ADD_NOTIFICATION_TEMPLATE, template);
const deleteNotificationTemplateByIdAsync = (docid: string) =>
  del(`${url.DELETE_NOTIFICATION_TMPLATE_BYID}/${docid}`);
const updateNotificationTemplateByIdAsync = (docid: string, data: any) =>
  put(`${url.UPDATE_NOTIFICATION_TEMPLATE_BYID}/${docid}`, data);
const createNotificationsAsync = (data: any) =>
  post(url.CREATE_NOTIFICATIONS, data);

const createNotificationsByFilterAsync = (data: any) =>
  post(url.CREATE_NOTIFICATIONS_BY_FILTER, data);

// group

const getGroupsByCompanyCodeAsync = (companyCode: string) =>
  get(`${url.GET_GROUPS_BY_COMPANYCODE}/${companyCode}`);
const getAllGroupsAsync = () => get(`${url.GET_ALL_GROUPS}`);
const getGroupByIdAsync = (docid: string) =>
  get(`${url.GET_GROUP_BY_ID}/${docid}`);
const addGroupAsync = (group: any) => post(url.ADD_GROUP, group);
const updateGroupByIdAsync = (docid: string, data: any) =>
  put(`${url.UPDATE_GROUP_BY_ID}/${docid}`, data);
const deleteGroupByIdAsync = (docid: string) =>
  del(`${url.DELETE_GROUP_BY_ID}/${docid}`);

//settings

const getAllOnboardingsAsync = () => get(`${url.GET_ALL_ONBOARDINGS}`);
const addOnboardingAsync = (onboarding: any) =>
  post(url.ADD_ONBOARDING, onboarding);
const fetchHelpVideoUrl = (path: string) =>
  post(`${url.GET_HELP_VIDEO_URL}`, { path });
const getAllMakes = () => get(`/makes/all`);
const addMake = (make: IMake) => post(`/makes/add`, make);
const deleteMake = (id: string) => del(`/makes/delete/${id}`);
const updateMake = (make: IMake) => put(`/makes/update`, make);

//Dealers
const getAllDealersAsync = () => get(`${url.GET_ALL_DEALERS}`);

//Claims
const fetchClaimsBySiteCode = (companyCode: string, siteCode: string) => {
  return get(`${url.FETCH_CLAIMS_BY_SITE_CODE}/${companyCode}/${siteCode}`);
}

const fetchClaimByCompanyCode = (companyCode: string) =>
  get(`${url.FETCH_CLAIMS_BY_COMPANY_CODE}/${companyCode}`);

const getCustomerById = (customerId: string) =>
  get(`${url.GET_CLAIMS_BY_ID}/${customerId}`);

const markClaim = (claimId: string) =>
  post(`${url.MAKE_CLAIM}/${claimId}`, {});

const sendMessage = (claimId: string, message: string) =>
  post(`${url.SEND_MESSAGE}/${claimId}`, { message });

const getMe = () => get(url.GET_ME);

export {
  getLoggedInUser,
  isUserAuthenticated,
  companyAdminLogin,
  loginStaffAsync,
  loginMFAAsync,
  resendMFAAsync,
  fetchCompaniesAsync,
  addNewCompanyAsync,
  deleteCompanyAsync,
  updateCompanyAsync,

  //site
  fetchSitesByCompanyCodeAsync,
  addSiteAsync,
  getCompanyByCodeAsync,
  getSiteByDocIdAsync,
  getSiteBySiteCodeAsync,
  updateSiteByDocIdAsync,
  deleteSiteBySiteCodeAsync,

  //admin
  getAdminsBySiteCodeAsync,
  getAdminsByCompanyCodeAsync,
  addAdminAsync,
  addAdminsAsync,
  getAdminByDocIdAsync,
  updateAdminByDocIdAsync,
  deleteAdminByDocIdAsyc,

  //admin scores
  getScoresByCompanyCodeAsynce,

  //customer
  getCustomersBySiteCodeAsync,
  getCustomersByCompanyCodeAsync,
  getCustomersAndVehiclesByCompanyCodeAsync,
  addCustomerAsync,
  inviteCustomerAsync,
  getCustomerByDocIdAsync,
  updateCustomerByDocIdAsync,
  deleteCustomerByDocIdAsync,
  getGraphDataOfCustomer,
  getCustomersByFilter,
  getCustomersByFilterCount,

  //vehicle
  getAllVehiclesAsync,
  addVehicleAsync,
  getVehicleByDocIdAsync,
  deleteVehicleByIdAsync,
  getVehiclesByCustomerIdAsync,
  getVehicleByRegNoAsync,
  updateVehicleAsync,

  //booking
  getBookingsByCompanyCodeAsync,
  getAllBookingsAsync,
  getBookingsBySiteCodeAsync,
  addBookingAsync,
  getBookingByDocIdAsync,
  deleteBookingByIdAsync,
  getGraphDataOfBooking,

  //product
  getProductsBySiteCodeAsync,
  getAllProductsAsync,
  getProductByDocIdAsync,
  addProductAsync,
  deleteProductByIdAsync,
  updateProductByDocIdAsync,
  createProductNotificationsAsync,
  getNotificationsByDocTypeAsync,
  createProductSalesAsync,
  invitedCustomerProudctNotificationAsync,
  getAllDealerProductsAsync,
  createContractAsync,
  refreshWasProductsAsync,

  setupWasDirectPayment,
  makeContractLive,
  getWasContractPDF,
  updateContractNumber,
  receivedDocument,

  //geofence
  addGeoFenceAsync,
  getAllGeoFenceListAsync,
  getGeoFenceByDocIdAsync,
  updateGeoFenceByDocIdAsync,
  deleteGeoFenceByIdAsync,

  //warranty
  getAllWarrantyListAsync,
  getWarrantByDocIdAsync,
  addWarrantyAsync,
  deleteWarrantyByIdAsync,
  updateWarrantyByDocIdAsync,

  //comms
  saveCommBySitecodeAsync,
  getCommsBySiteCodeAsync,

  //booking tool setting
  saveBookingToolBySitecodeAsync,
  getBookingToolDataBySiteCodeAsync,

  //directories
  getDirectoryBySiteCodeAsync,
  getSitesByServiceIdAsync,
  updateDirStatusByServiceAsync,
  getDirectoriesBySite,
  updateDirectoriesBySite,

  //roles
  getAllRolesAsync,
  addRoleAsync,
  getRoleByIdAsync,
  deleteRoleByIdAsync,
  updateRoleByIdAsync,

  //saleOfProducts
  getAllSaleOfProductsAsync,
  addSaleOfProductAsync,
  getContractByAgreementAsync,

  //notifications
  getNotificationsByCustomerIdAsync,
  getNotificationsByCompanyCodeAsync,
  getNotificationsBySiteCodeAsync,
  getNotificationsByDocIdAsync,
  addNotificationAsync,
  deleteNotificationByIdAsync,
  updateNotificationByIdAsync,

  //notification-templates
  getNotificationTemplatesByCompanyCodeAsync,
  getNotificationTemplateByIdAsnc,
  addNotificationTemplateAsync,
  deleteNotificationTemplateByIdAsync,
  updateNotificationTemplateByIdAsync,
  createNotificationsAsync,
  createNotificationsByFilterAsync,

  //groups
  getGroupsByCompanyCodeAsync,
  getAllGroupsAsync,
  getGroupByIdAsync,
  addGroupAsync,
  updateGroupByIdAsync,
  deleteGroupByIdAsync,

  //settings
  getAllOnboardingsAsync,
  addOnboardingAsync,
  fetchHelpVideoUrl,
  getAllMakes,
  addMake,
  deleteMake,
  updateMake,

  //dealers
  getAllDealersAsync,
  getMe,

  //analytics
  getFromFirebase,
  getAnalyticsCustomersVehicles,

  //claims
  fetchClaimsBySiteCode,
  fetchClaimByCompanyCode,
  getCustomerById,
  markClaim,
  sendMessage
};
