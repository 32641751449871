import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, } from "reactstrap";
import { withTranslation } from "react-i18next";
import { withRouter, Link, useHistory } from "react-router-dom";
import user1 from "../../../assets/images/users/avatar-1.jpg";
import { useSelector } from "react-redux";
import { getV2AuthUrl, getV2LogoutUrl } from "src/helpers/v2Helper";

const ProfileMenu = (props: any) => {
  let history = useHistory()
  const [menu, setMenu] = useState<boolean>(false);
  const [username, setusername] = useState("");
  const { currentUser } = useSelector((state: any) => state.login)
  const [authUser, setAuthUser] = useState<any>(null);
  const { authSite } = useSelector((state: any) => state.site)
  const [initials, setInitials] = useState("");

  useEffect(() => {
    const getAuthUser = sessionStorage.getItem("authUser");
    if (getAuthUser) {
      const obj = JSON.parse(getAuthUser);
      if (obj) {
        setAuthUser(obj);
        setInitials(obj?.firstName?.charAt(0) + obj?.lastName?.charAt(0));
      }
    }
  }, []);

  useEffect(() => {
    if (currentUser) {

      if (currentUser?.firstName && currentUser?.lastName) {
        setInitials(currentUser.firstName?.charAt(0) + currentUser.lastName?.charAt(0));
      } else {
        if (currentUser?.name) {
          setInitials(currentUser.name.charAt(0));
        }
      }
      console.log("INITIALS", initials)
    }
  }, [currentUser]);

  const profile = () => {

    const accountUrl = `${getV2AuthUrl()}/account?returnUrl=${encodeURIComponent(window.location.href)}`;
    window.location.href = accountUrl
  }

  const onSignOut = () => {

    sessionStorage.removeItem('accessToken')
    sessionStorage.removeItem('authUser')

    if (currentUser.fromV2) {
      const logoutUrl = getV2LogoutUrl();
      window.location.replace(logoutUrl);
    } else {
      history.push('/login')
    }
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item bg-soft-light border-start border-end"
          id="page-header-user-dropdown"
          tag="button"
        >

          {/* use logo if available, otherwise use initials */}

          {currentUser?.logo &&
            <img
              className="rounded-circle header-profile-user"
              src={currentUser.logo}
              alt=""></img>
          }

          {!currentUser?.logo && initials &&
            <div className="rounded-circle header-profile-user initials">
              {initials}
            </div>
          }

          {/* {currentUser?.LOGIN_METHOD === "ADMIN_LOGIN" && (
            <>
              <img
                className="rounded-circle header-profile-user"
                src={currentUser?.avatar}
                alt=""
              />
              <span className="d-none d-xl-inline-block ms-2 me-1">{currentUser?.userName}</span>
            </>
          )}

          {currentUser?.LOGIN_METHOD === "COMPANY_LOGIN" && (
            <>
              <img
                className="rounded-circle header-profile-user"
                src={currentUser?.logo}
                alt=""
              />
              <span className="d-none d-xl-inline-block ms-2 me-1">{currentUser?.name}</span>
            </>
          )} */}

          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          {/* <DropdownItem tag="a" href="/profile">
            {" "}
            <i className="bx bx-user font-size-16 align-middle me-1" />
            {props.t("Profile")}{" "}
          </DropdownItem> */}
          {/*           <DropdownItem tag="a" href="/#">
            <i className="bx bx-lock-open font-size-16 align-middle me-1" />
            {props.t("Lock screen")}
          </DropdownItem> */}
          {currentUser?.fromV2 &&
            <>
              <DropdownItem className="dropdown-item" onClick={() => profile()}>
                <i className="bx bx-user font-size-16 align-middle me-1" />
                <span>{props.t("Your Account")}</span>
              </DropdownItem>
              <div className="dropdown-divider" />
            </>
          }
          <DropdownItem className="dropdown-item" onClick={() => onSignOut()}>
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
};
export default withTranslation()(withRouter(ProfileMenu));
