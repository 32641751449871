import Icon from "@ailibs/feather-react-ts/dist/Icon";
import React, { useState } from "react";
import { ThemeColors } from "src/constants/colors";
import useMakes from "src/hooks/useMakes";
import { IMake, IModel, ISite } from "src/interfaces/general";
import styled from "styled-components";
import Slider from "@mui/material/Slider";
import moment from "moment";
import Select from "react-select";
import useSites from "src/hooks/useSites";
import { useSelector } from "react-redux";

interface IProps {
  onChange: (value: any) => void;
  focused: boolean;
}

interface ISiteSelectedOption {
  value: ISite | null;
  label: string;
}

const CheckBox = ({
  onChange,
  label,
  initialChecked,
}: {
  onChange: (value: boolean) => void;
  label: string;
  initialChecked?: boolean;
}) => {
  const [checked, setChecked] = useState(initialChecked || false);

  const onCheck = () => {
    setChecked(!checked);
    onChange(!checked);
  };

  return (
    <CheckBoxContainer onClick={onCheck}>
      <input className="form-check-input" type="checkbox" checked={checked} />
      <div className="label">{label}</div>
    </CheckBoxContainer>
  );
};

const Filtering = (props: IProps) => {
  const user = useSelector((state: any) => state.login.currentUser);
  const { sites } = useSites(user?.code || user?.companyCode);

  const { makes, fetchMakes } = useMakes();
  const [selectedMakes, setSelectedMakes] = useState<IMake[]>([]);
  const [models, setModels] = useState<IModel[]>([]);
  const [selectedModels, setSelectedModels] = useState<IModel[]>([]);
  const [selected, setSelected] = useState<number>(-1);
  const [yearRange, setYearRange] = useState<number[]>([2000, 2023]);
  const [mileageRange, setMileageRange] = useState<number[]>([0, 100000]);
  const [currentSite, setCurrentSite] = useState<any>([]);
  const [checked, setChecked] = useState<boolean>(true);
  const sections = [
    {
      name: "Make",
      items: makes,
    },
    {
      name: "Model",
      items: models,
    },
    {
      sectionId: "year-range", // 'year-range
      name: "Year Range",
      items: [],
    },
    {
      sectionId: "milage-range", // 'milage-range
      name: "Mileage Range",
      items: [],
    },
    {
      sectionId: "branch", // 'branch
      name: "Branch",
      items: [],
    },
  ];

  const handleSiteChange = (selectedOption: ISiteSelectedOption) => {
    setCurrentSite(selectedOption);
  }

  const onChange = (sectionIndex: number, value: IMake, checked: boolean) => {
    if (sectionIndex == 0) {
      if (checked) {
        setSelectedMakes([...selectedMakes, value]);
        setModels([...models, ...value.models]);
      } else {
        const newMakes = selectedMakes.filter(
          (item: IMake) => item.id !== value.id
        );
        setSelectedMakes(newMakes);
        setModels(
          newMakes.reduce((acc: IModel[], item: IMake) => {
            return [...acc, ...item.models];
          }, [])
        );
      }
    }
    if (sectionIndex == 1) {
      if (checked) {
        setSelectedModels([...selectedModels, value]);
      } else {
        setSelectedModels(
          selectedModels.filter((item: IModel) => item.name !== value.name)
        );
      }
    }
  };

  const onSelectSection = (index: number) => {
    if (!props.focused) {
      return;
    }
    if (selected == index) {
      setSelected(-1);
    } else {
      setSelected(index);
    }
  };

  React.useEffect(() => {
    if (props.focused) {
      fetchMakes();
    } else {
      setSelected(-1);
    }
  }, [props.focused]);

  React.useEffect(() => {
    props.onChange({
      makes: selectedMakes,
      models: selectedModels.map((item: IModel) => item.name),
      yearRange,
      mileageRange,
      site: currentSite?.value?.code,
      noVehicleFilter: checked,
    });
  }, [selectedMakes, selectedModels, yearRange, mileageRange, currentSite, checked]);

  return (
    <Container disabled={!props.focused} className='overflow-auto'>
      {sections.map((section: any, index: number) => (
        <div key={index}>
          <div className="section-name" onClick={() => onSelectSection(index)}>
            {section.name}
            {index == selected ? (
              <Icon name="chevron-up" size={20} color={ThemeColors.white} />
            ) : (
              <Icon name="chevron-down" size={20} color={ThemeColors.white} />
            )}
          </div>
          <div
            className="section-items"
            style={{ display: selected === index ? "flex" : "none" }}
          >
            {section.items?.map((item: any, i: number) => (
              <div key={i} className="section-item">
                <CheckBox
                  onChange={checked => onChange(index, item, checked)}
                  label={item.name}
                />
              </div>
            ))}
            {section.sectionId == "year-range" && (
              <div className="section-content">
                <Slider
                  getAriaLabel={() => "Year range"}
                  value={yearRange}
                  onChange={(_, value) => {
                    if (Array.isArray(value)) {
                      setYearRange(value);
                    }
                  }}
                  valueLabelDisplay="auto"
                  min={2000}
                  max={moment().year()}
                />
              </div>
            )}
            {section.sectionId == "milage-range" && (
              <div className="section-content">
                <Slider
                  getAriaLabel={() => "Mileage range"}
                  value={mileageRange}
                  onChange={(_, value) => {
                    if (Array.isArray(value)) {
                      setMileageRange(value);
                    }
                  }}
                  valueLabelDisplay="auto"
                  min={0}
                  step={1000}
                  max={100000}
                />
              </div>
            )}
            {section.sectionId == "branch" && (
              <div className="section-content">
                <Select
                  value={currentSite.value
                    ? {
                      value: currentSite.value,
                      label: currentSite.value.name,
                    }
                    : null
                  }
                  onChange={handleSiteChange}
                  options={
                    sites.length > 0
                      ? [{ value: null, label: "ALL Sites" }].concat(
                        sites.map((site: any) => ({
                          value: site,
                          label: site?.name,
                        }))
                      )
                      : [{ value: null, label: "Loading" }]
                  }
                />
              </div>
            )}

          </div>
        </div>
      ))}
      <div className="section-content">
        <CheckBox
          onChange={setChecked}
          label={"No Vehicle Filter"}
          initialChecked={checked}
        />
      </div>
    </Container>
  );
};

export default Filtering;

const Container = styled.div<{ disabled: boolean }>`
  padding: 20px;
  height: 500px;
  overflow: scroll;

  .section-name {
    font-weight: bold;
    margin-bottom: 10px;
    background-color: ${props => (props.disabled ? "grey" : ThemeColors.green)};
    padding: 10px;
    border-radius: 10px;
    color: ${ThemeColors.white};
    cursor: pointer;
    display: flex;
    justify-content: space-between;
  }

  .section-items {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;

    .section-item {
      padding: 10px;
      margin-right: 10px;
      margin-bottom: 10px;
      cursor: pointer;
    }

    .section-content {
      padding: 10px;
      width: 100%;
    }
  }
`;

const CheckBoxContainer = styled.div`
  display: flex;
  cursor: pointer;

  .label {
    margin-left: 10px;
  }
`;
