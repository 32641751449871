export const useV2 = () => {
    var v2Tenants = (localStorage.getItem("v2Tenants") ?? "").split(",");
    const currentTenant = sessionStorage.getItem("companyCode");
    if (!currentTenant) {
        return false;
    }
    return v2Tenants.includes(currentTenant);
}

export const addToV2 = (companyCode: string) => {

    if (companyCode.toLowerCase() === "no") {
        //clear tenant codes
        localStorage.removeItem("v2Tenants");
        return;
    }

    var v2TenantsCache = localStorage.getItem("v2Tenants");
    let v2Tenants: string[];
    if (!v2TenantsCache) {
        v2Tenants = [];
    } else {
        v2Tenants = v2TenantsCache.split(",");
    }
    if (!v2Tenants.includes(companyCode)) {
        v2Tenants.push(companyCode);
        localStorage.setItem("v2Tenants", v2Tenants.join(","));
    }
}

export const getV2AuthUrl = () => {

    //todo: this function will need to be updated if we have multiple tenants
    const host = window.location.hostname;

    switch (host) {
        case "localhost":
            return "https://auth.dev.hendy.aulimitless.com/v1"
        case "hendy-production.web.app":
        case "hendy-production.firebaseapp.com":
            return "https://auth.uat.hendy.aulimitless.com/v1";
        case "hendy-hosting.web.app":
        case "hendy-hosting.firebaseapp.com":
            return "https://auth.hendy.aulimitless.com/v1";
        default:
            alert("SSO URL not configured for " + host)
            return null;
    }
}

export const getV2LoginUrl = () => {
    const returnUrl = getReturnUrl();
    return `${getV2AuthUrl()}/login?autoReturn=1&returnUrl=${encodeURIComponent(returnUrl)}`
}

export const getV2LogoutUrl = (host?: string) => {
    const returnUrl = getReturnUrl();
    return `${getV2AuthUrl()}/logout?returnUrl=${encodeURIComponent(returnUrl)}`
}

const getReturnUrl = () => {
    const returnUrl = new URL(window.location.href);
    const params = returnUrl.searchParams;

    if (params.has('token')) {
        params.delete('token');
    }

    const cleanReturnUrl = returnUrl.origin + returnUrl.pathname + (params.toString() ? '?' + params.toString() : '');
    return cleanReturnUrl;
}  