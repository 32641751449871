import React, { useRef, useState } from 'react';
import Slider from "react-slick";
import { Container } from 'reactstrap';
import NavHeader from 'src/components/Common/NavHeader';
import SelectTemplate from './components/SelectTemplate';
import NotificationCard from './components/NotificationCard';
import Filtering from './components/Filtering';
import SelectCustomer from './components/SelectCustomer';
import useNotificationTemplates from 'src/hooks/useNotiffcationTemplates';
import { useHistory } from 'react-router';
import useCustomers from 'src/hooks/useCustomers';



const AddNotification = () => {
    const history = useHistory();
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        swipe: false
    }

    const sliderRef = React.createRef<typeof Slider>();
    const [template, setTemplate] = useState<any>(null);
    const [filter, setFilter] = useState<any>(null);
    const [index, setIndex] = useState<number>(0);
    /*     const [customers, setCustomers] = useState<any>([]);
        const [customerCount, setCustomerCount] = useState<number>(0); */
    const { sendTemplate, sendTemplateToAll } = useNotificationTemplates();
    const [loading, setLoading] = useState<boolean>(false);
    const [sendingToSelected, setSendingToSelected] = useState<boolean>(false);
    const [sendingToAll, setSendingToAll] = useState<boolean>(false);
    const [moving, setMoving] = useState<boolean>(false);
    const customersRef = useRef<any>(null);
    const { customers, customerCount, setCustomers, setCustomerCount } = useCustomers();

    const onNext = () => {
        if (index == 0 && !template) return;
        if (index == 1) {
            if (!filter) return;
            customersRef.current?.fetch();
        }
        if (index === 2) {
            onSend();
            return;
        }
        setMoving(true);
        setTimeout(() => {
            setMoving(false);
        }, 1000)
        setIndex(index + 1);
        sliderRef.current.slickNext();
    }

    const onPrev = () => {
        if (index == 0) return;
        setCustomerCount(0);
        setCustomers([]);
        setIndex(index - 1);
        sliderRef.current.slickPrev();
    }

    const onChangeTemplate = (template: any) => {
        setTemplate(template);
    }

    const onChangeFilter = (filter: any) => {
        setFilter(filter);
    }

    const onSelectCustomers = (customers: any) => {
        const updatedCustomers = customers.map((customer: any) => ({
            ...customer,
            regNo: customer.regNo !== undefined ? customer.regNo : undefined
        }));
        setCustomers(updatedCustomers);
    }

    const onSend = async () => {
        setLoading(true)
        setSendingToSelected(true)
        try {
            await sendTemplate({ template, customers });
            history.goBack();
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
            setSendingToSelected(false)
        }
    }



    const sendToAll = async () => {
        setLoading(true)
        setSendingToAll(true)
        try {
            await sendTemplateToAll({ template, filter });
            history.goBack();
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
            setSendingToAll(false)
        }
    }

    return (
        <>
            <div className="page-content" style={{ height: '100vh', overflow: 'scroll' }}>
                <Container fluid>
                    <NavHeader
                        title='Add Notification'
                        description='You can send a notification to a specified user.'
                    />
                    <Slider ref={sliderRef} {...settings}>
                        <div >
                            <SelectTemplate
                                onChange={onChangeTemplate}
                            />
                        </div>
                        <div >
                            <NotificationCard
                                template={template}
                            />
                        </div>
                        <div >
                            <Filtering
                                onChange={onChangeFilter}
                                focused={index == 1}
                            />
                        </div>
                        <div style={{ height: "100%", background: "red" }}>
                            <SelectCustomer
                                ref={customersRef}
                                filter={filter}
                                onCount={(count: number) => { console.log("COUNTED", count); setCustomerCount(count) }}
                                onSelect={onSelectCustomers}
                            />
                        </div>
                    </Slider>
                    <div className='d-flex justify-content-between mt-3'>
                        <button
                            type="button"
                            className="btn btn-primary w-md"
                            onClick={onPrev}
                        >
                            {index == 0 ? "Cancel" :
                                index == 2 ? "Edit" : "Back"}
                        </button>
                        <div>
                            <button
                                type="button"
                                className="btn btn-primary w-md"
                                onClick={index == 2 ? onSend : onNext}
                                disabled={moving || sendingToAll || sendingToSelected || loading || (index == 2 && customers.length == 0)}
                            >
                                {index == 2 ? `Send to selected (${customers.length})` : "Next"}
                                {sendingToSelected && <div className="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></div>}
                            </button>
                            {index == 2 &&
                                <button
                                    type="button"
                                    className="btn btn-primary w-md ms-2"
                                    onClick={sendToAll}
                                    disabled={moving || sendingToAll || sendingToSelected || loading || (index == 2 && customerCount === 0)}
                                >
                                    {`Send to all (${customerCount})`}
                                    {sendingToAll && <div className="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></div>}
                                </button>
                            }
                        </div>
                    </div>
                </Container>
            </div>
        </>
    )


}

export default AddNotification;