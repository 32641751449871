import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { AuthenticationState, AzureAD } from 'react-aad-msal';
import { authProvider } from './AADProvider';
import { useHistory } from 'react-router';
import { getMe } from 'src/helpers/backend_helper';
import { axiosApi } from 'src/helpers/api_helper';
import AuthUI from './AuthUI';
import { useDispatch } from "react-redux";
import { setAuthUser } from "src/store/actions";
import { useV2 } from "src/helpers/v2Helper";





const HendyStaffLogin = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();

    if (useV2()) {
        //redirect to dashboard to trigger SSO login
        window.location.href = "/dashboard";
    }

    const goProduct = (accountInfo: any) => {
        sessionStorage.setItem('accessToken', accountInfo.jwtIdToken);
        axiosApi.defaults.headers.common["Authorization"] = "Bearer " + accountInfo.jwtIdToken;
        getMe().then((user: any) => {
            sessionStorage.setItem("authUser", JSON.stringify({
                ...user,
                LOGIN_METHOD: 'ADMIN_LOGIN'
            }))
            dispatch(setAuthUser({
                ...user,
                LOGIN_METHOD: 'ADMIN_LOGIN'
            }));
            setTimeout(() => {
                history.push('/products');
            }, 100);
        }).catch(console.log);

        return <AuthUI showLogin={false} />;
    }

    return (
        <AzureAD provider={authProvider} forceLogin={true}>
            {
                ({ login, logout, authenticationState, error, accountInfo }: any) => {
                    switch (authenticationState) {
                        case AuthenticationState.Authenticated:
                            return goProduct(accountInfo);
                        case AuthenticationState.Unauthenticated:
                            return (<AuthUI login={login} showLogin={true} />);
                        case AuthenticationState.InProgress:
                            return (<AuthUI showLogin={false} />);
                    }
                }
            }
        </AzureAD>
    )
}


export default HendyStaffLogin;

const LogoImg = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
`
