import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "react-drawer/lib/react-drawer.css";
import Icon from "@ailibs/feather-react-ts";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
import { useSelector, useDispatch } from "react-redux";
import { getAllSitesByCompanyCode, getSiteBySiteCode, getCompanyByCode } from '../../store/actions'
import { useTranslation } from "react-i18next";
import useWindowSize from "src/hooks/useWindowSize";

const Header = (props: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [width] = useWindowSize();


  const { currentUser } = useSelector((state: any) => state.login)
  const { authSite } = useSelector((state: any) => state.site)

  const [search, setsearch] = useState<boolean>(false);
  const [isClick, setClick] = useState<boolean>(true);

  useEffect(() => {
    // if(currentUser?.LOGIN_METHOD === "ADMIN_LOGIN") {
    //   dispatch(getSiteBySiteCode(currentUser.siteCode));
    // }
    // if(currentUser?.LOGIN_METHOD === "COMPANY_LOGIN") {
    //   dispatch(getCompanyByCode(currentUser.code));
    // }
  }, [currentUser])

  useEffect(() => {
    if (currentUser?.LOGIN_METHOD === "COMPANY_LOGIN") {
      dispatch(getAllSitesByCompanyCode(currentUser.code));
    }

    if (currentUser?.LOGIN_METHOD === "ADMIN_LOGIN") {
      //dispatch(getSiteBySiteCode(currentUser.siteCode));
      //no longer filtering admin data by site
      dispatch(getAllSitesByCompanyCode(currentUser.companyCode));

    }
  }, [currentUser]);

  /*** Sidebar menu icon and default menu set */
  function tToggle() {
    var body = document.body;
    setClick(!isClick);
    if (isClick === true) {
      body.classList.add("sidebar-enable");
      document.body.setAttribute("data-sidebar-size", "sm");
    } else {
      body.classList.remove("sidebar-enable");
      document.body.setAttribute("data-sidebar-size", "lg");
    }
  }

  useEffect(() => {
    if (width && width < 992 && isClick === true) {
      setClick(false);
      document.body.classList.remove("sidebar-enable");
      document.body.setAttribute("data-sidebar-size", "sm");
    }
    if (width && width >= 992 && isClick === false) {
      setClick(true);
      document.body.classList.add("sidebar-enable");
      document.body.setAttribute("data-sidebar-size", "lg");
    }
  }, [width]);

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="/dashboard" className="logo logo-dark">
                {currentUser?.LOGIN_METHOD === "ADMIN_LOGIN" && (
                  <>
                    <span className="logo-sm">
                      <img src={authSite?.logo} alt="" height="24" />
                    </span>
                    <span className="logo-lg">

                      <img src={authSite?.logo} alt="" height="24" />{" "}
                      <span className="logo-txt">{authSite?.name}</span>
                    </span>
                  </>
                )}

                {currentUser?.LOGIN_METHOD === "COMPANY_LOGIN" && (
                  <>
                    <span className="logo-sm">
                      <img src={currentUser?.logo} alt="" height="24" />
                    </span>
                    <span className="logo-lg">
                      <img src={currentUser?.logo} alt="" height="24" />{" "}
                      <span className="logo-txt">{currentUser?.name}</span>
                    </span>
                  </>
                )}

              </Link>

              <Link to="/dashboard" className="logo logo-light">
                {currentUser?.LOGIN_METHOD === "ADMIN_LOGIN" && (
                  <>
                    <span className="logo-sm">
                      <img src={authSite?.logo} alt="" height="24" />
                    </span>
                    <span className="logo-lg">
                      <img src={authSite?.logo} alt="" height="24" />{" "}
                      <span className="logo-txt">{authSite?.name}</span>
                    </span>
                  </>
                )}

                {currentUser?.LOGIN_METHOD === "COMPANY_LOGIN" && (
                  <>
                    <span className="logo-sm">
                      <img src={currentUser?.logo} alt="" height="24" />
                    </span>
                    <span className="logo-lg">
                      <img src={currentUser?.logo} alt="" height="24" />{" "}
                      <span className="logo-txt">{currentUser?.name}</span>
                    </span>
                  </>
                )}

              </Link>
            </div>

            <button
              onClick={() => {
                tToggle();
              }}
              type="button"
              className="btn btn-sm px-3 font-size-16 header-item"
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars"></i>
            </button>

            <form className="app-search d-none d-lg-block">
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control"
                  placeholder={t("search") + "..."}
                />
                <button className="btn btn-primary" type="button">
                  <i className="bx bx-search-alt align-middle"></i>
                </button>
              </div>
            </form>
          </div>
          <div className="d-flex">
            <div className="dropdown d-inline-block d-lg-none ms-2">
              <button
                onClick={() => {
                  setsearch(!search);
                }}
                type="button"
                className="btn header-item noti-icon "
                id="page-header-search-dropdown"
              >
                <Icon name="search" className="icon-lg" />
              </button>
              <div
                className={
                  search
                    ? "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 show"
                    : "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                }
                aria-labelledby="page-header-search-dropdown"
              >
                <form className="p-3">
                  <div className="form-group m-0">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search ..."
                        aria-label="Recipient's username"
                      />
                      <div className="input-group-append">
                        <button className="btn btn-primary" type="submit">
                          <i className="mdi mdi-magnify" />
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <LanguageDropdown />
            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

export default Header;
